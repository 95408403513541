import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TooltipTrigger from 'react-popper-tooltip';
import { boxShadow } from 'styled-system';
import { Box, Button } from 'rebass';
import 'react-popper-tooltip/dist/styles.css';

/**
 *
 * @typedef {import('react').ReactNode} ReactNode
 */

/**
 *
 * @param {import('react-popper-tooltip/dist/types').TooltipTriggerProps & { children: ReactNode, anchor: ReactNode, closeOnItemClick: Boolean }} props
 */
export function Menu(props) {
  const [opened, setOpened] = React.useState(false);
  const { children, anchor, closeOnItemClick, as, ...rest } = props;

  return (
    <TooltipTrigger
      trigger="click"
      usePortal={false}
      tooltipShown={opened}
      onVisibilityChange={setOpened}
      {...rest}
      tooltip={({ getTooltipProps, placement, tooltipRef }) => (
        <MenuContainer
          {...getTooltipProps({ ref: tooltipRef })}
          data-placement={placement}
        >
          {React.Children.map(children, child => {
            function onClick(event) {
              if (child.props.onClick) {
                child.props.onClick(event);
              }
              if (closeOnItemClick) {
                setOpened(false);
              }
            }
            if (React.isValidElement(child)) {
              return React.cloneElement(child, { onClick });
            }
            return null;
          })}
        </MenuContainer>
      )}
    >
      {({ getTriggerProps, triggerRef }) => (
        <TriggerContainer as={as} {...getTriggerProps({ ref: triggerRef })}>
          {anchor}
        </TriggerContainer>
      )}
    </TooltipTrigger>
  );
}

Menu.propTypes = {
  // eslint-disable-next-line react/require-default-props
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  anchor: PropTypes.node.isRequired,
  children: PropTypes.node,
  closeOnItemClick: PropTypes.bool,
};

Menu.defaultProps = {
  children: null,
  // eslint-disable-next-line react/default-props-match-prop-types
  placement: 'bottom',
  closeOnItemClick: true,
};

const MenuItem = React.forwardRef(
  /**
   * @param {import('rebass').BoxProps & { children: ReactNode, icon: ReactNode, onClick: Function }} props
   */
  (props, ref) => {
    const { children, icon, onClick, ...rest } = props;

    return (
      <MenuItemContainer ref={ref} onClick={onClick} {...rest}>
        {icon && <MenuItemIcon>{icon}</MenuItemIcon>}
        {children}
      </MenuItemContainer>
    );
  },
);

MenuItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  // eslint-disable-next-line react/require-default-props
  onClick: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  icon: PropTypes.node,
};

MenuItem.defaultProps = {
  children: null,
};

const MenuContainer = styled(Box)`
  z-index: 20;
  border-radius: 4px;
  overflow: hidden;
  transition: opacity 0.2s;
  ${boxShadow};
`;

MenuContainer.defaultProps = {
  boxShadow: 1,
  bg: 'white',
};

const MenuItemContainer = styled(Button)`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: transparent;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
  border-radius: 4px;
  &:hover:not(:disabled) {
    background-color: rgba(0, 158, 255, 0.125);
  }
  &:disabled {
    cursor: not-allowed;
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

MenuItemContainer.defaultProps = {
  color: 'text',
  px: 3,
  py: 10,
};

const MenuItemIcon = styled.div`
  margin-right: 12px;
  & svg {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.textSecondary};
    vertical-align: middle;
  }
`;

const TriggerContainer = styled.span``;

Menu.Item = MenuItem;
