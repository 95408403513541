import React from 'react';
import { t } from '@lingui/macro';
import { i18n } from '@lib/i18n';

const EmployeeMainPage = React.lazy(() =>
  import('./pages/main').then(module => ({
    default: module.EmployeeMainPage,
  })),
);

export const routes = [
  {
    path: '/hr/employees',
    component: EmployeeMainPage,
    navigation: {
      name: i18n._(t`Employees`),
      shortName: 'E',
      onlyTopLevelLink: true,
    },
  },
];
