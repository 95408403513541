/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Image } from 'rebass';
import styled from 'styled-components';
import dayjs from 'dayjs';

import noLeavesImage from '@lib/illustrations/no-leaves-found.svg';

import {
  Typography,
  IconButton,
  DataTable,
  RadialBar,
  Previewer,
  Link,
} from '@oca/ui';
import { guidRegex, fileExtensionRegex } from '@lib/regex';
import { fileIcon, fetcher, AccessControl } from '@features/common';
import emptyListIllustration from '@lib/illustrations/no-data-found.svg';

import { Download } from '@oca/icons';
import { Masonry } from '@lib/masonry';
import { saveFile } from '@lib/helpers';
import { Trans } from '@lingui/macro';
import { useStore } from 'effector-react';
import { ProfileBlock } from '../molecules';
import { documentLabelsMap } from '../model/static';
import { LeaveAdjustmentForm } from './leave-adjustment-form';
import {
  $leaveAdjustDialogId,
  closeLeaveAdjustDialog,
  openLeaveAdjustDialog,
} from '../model/profile';

const leaveTypesLabelsMap = {
  open: <Trans>Pending</Trans>,
  approved: <Trans>Approved</Trans>,
  rejected: <Trans>Rejected</Trans>,
};

export function EmployeeProfileBlocks({ blockIndex, profile }) {
  const Block = blocks[blockIndex] || null;

  return (
    <Box paddingTop={24}>{Block && <Block {...profile} _user={profile} />}</Box>
  );
}

EmployeeProfileBlocks.propTypes = {
  blockIndex: PropTypes.number.isRequired,
  profile: PropTypes.shape({
    fullName: PropTypes.string,
  }).isRequired,
};

const blocks = [
  BasicInfoBlock,
  ContactsInfoBlock,
  DocumentsInfoBlock,
  AllowancesInfoBlock,
];

function BasicInfoBlock({
  birthday,
  department,
  employmentStatus,
  fullName,
  gender,
  hiredDate,
  id,
  isResident,
  jobTitle,
  leaves,
  maritalStatus,
  nationality,
  rfid,
  shirtSize,
  _user,
}) {
  const basicInfoList = [
    { label: <Trans>Full name</Trans>, value: fullName },
    { label: <Trans>Birthday</Trans>, value: midFormat(birthday) },
    { label: <Trans>Gender</Trans>, value: gender },
    { label: <Trans>Marital status</Trans>, value: maritalStatus },
    { label: <Trans>Nationality</Trans>, value: nationality },
    {
      label: <Trans>Residential status</Trans>,
      value: isResident ? <Trans>Resident</Trans> : <Trans>Non resident</Trans>,
    },
  ];

  const companyInfoList = [
    { label: <Trans>RFID</Trans>, value: rfid },
    {
      label: <Trans>Department</Trans>,
      value: department ? department.name : '',
    },
    { label: <Trans>Job title</Trans>, value: jobTitle },
    {
      label: <Trans>Employment status</Trans>,
      value:
        employmentStatus === 'full_time' ? (
          <Trans>Full time</Trans>
        ) : (
          <Trans>Part time</Trans>
        ),
    },
    { label: <Trans>Shirt size</Trans>, value: shirtSize },
    { label: <Trans>Hired date</Trans>, value: midFormat(hiredDate) },
  ];

  const leavesColumns = [
    { Header: <Trans>Leave type</Trans>, accessor: 'type.name' },
    {
      Header: <Trans>From</Trans>,
      id: 'from',
      accessor: d => midFormat(d.dateFrom),
    },
    {
      Header: <Trans>To</Trans>,
      id: 'to',
      accessor: d => midFormat(d.dateTo),
    },
    {
      Header: <Trans>Status</Trans>,
      id: 'status',
      accessor: d => leaveTypesLabelsMap[d.status] || '',
    },
  ];

  const leaveAdjustmentColumns = [
    {
      Header: <Trans>Leave type</Trans>,
      id: 'leaveType',
      accessor: data => {
        const type = leaves.types.find(item => item.id === data.leaveType);
        return type ? type.name : 'N/A';
      },
    },
    {
      Header: <Trans>Created by</Trans>,
      id: 'createdByName',
      accessor: data => data.createdByName || 'N/A',
    },
    {
      Header: <Trans>Date</Trans>,
      id: 'date',
      accessor: data => fullFormat(data.date),
    },
    {
      Header: <Trans>Amount</Trans>,
      id: 'amount',
      accessor: data => {
        const value = Math.abs(data.adjustment);
        const sign = data.adjustment > 0 ? '+' : '-';
        return (
          <Trans>
            {sign} {value} (days)
          </Trans>
        );
      },
    },
    {
      Header: <Trans>Balance</Trans>,
      id: 'balance',
      accessor: data => data.leaveBalanceAfter || 'N/A',
    },
    {
      Header: <Trans>Reason</Trans>,
      accessor: 'reason',
    },
  ];

  return (
    <React.Fragment>
      <Box paddingBottom={24}>
        <ProfileBlock
          title={<Trans>Basic information</Trans>}
          list={basicInfoList}
        />
      </Box>
      <Box paddingBottom={24}>
        <ProfileBlock
          title={<Trans>Company information</Trans>}
          list={companyInfoList}
        />
      </Box>
      {leaves && (
        <ProfileBlock title={<Trans>Leaves</Trans>}>
          <Flex flexWrap="wrap" style={{ gap: 16 }} mt={2} mb={64}>
            {leaves.types.map(type => (
              <AvailableLeaveType key={type.id} type={type} user={_user} />
            ))}
          </Flex>
          <Flex flexDirection="column">
            <Box width={1}>
              <Typography variant="h2" gutterBottom>
                <Trans>Leaves history</Trans>
              </Typography>
              <DataTable
                className="-striped"
                data={leaves ? leaves.list : []}
                noDataText={
                  <Flex flexDirection="column" alignItems="center">
                    <Image src={noLeavesImage} mb={2} />
                    <Trans>No leaves</Trans>
                  </Flex>
                }
                columns={leavesColumns}
                pageSize={10}
              />
            </Box>
            <Box width={1}>
              <Typography variant="h2" gutterBottom>
                <Trans>Leaves adjustment history</Trans>
              </Typography>
              <DataTable
                className="-striped"
                data={leaves ? leaves.adjustments : []}
                noDataText={
                  <Flex flexDirection="column" alignItems="center">
                    <Image src={noLeavesImage} mb={2} />
                    <Trans>No leaves</Trans>
                  </Flex>
                }
                columns={leaveAdjustmentColumns}
                pageSize={10}
              />
            </Box>
          </Flex>
        </ProfileBlock>
      )}
    </React.Fragment>
  );
}

function ContactsInfoBlock({
  street,
  contacts: emergencyContacts,
  email,
  homePhone,
  mobilePhone,
  workPhone,
}) {
  const contactsInfoBlock = [
    { label: <Trans>Work phone</Trans>, value: workPhone },
    { label: <Trans>Mobile phone</Trans>, value: mobilePhone },
    { label: <Trans>Home phone</Trans>, value: homePhone },
    { label: <Trans>Email</Trans>, value: email },
    { label: <Trans>Home Address</Trans>, value: street },
  ];

  return (
    <React.Fragment>
      <Box paddingBottom={24}>
        <ProfileBlock
          title={<Trans>Contact information</Trans>}
          list={contactsInfoBlock}
        />
      </Box>
      {emergencyContacts.map(
        (
          {
            relationship,
            mobilePhone: emMobilePhone,
            homePhone: emHomePhone,
            address: emAddress,
          },
          index,
        ) => {
          const list = [
            {
              label: <Trans>Name and relationship</Trans>,
              value: relationship,
            },
            { label: <Trans>Mobile phone</Trans>, value: emMobilePhone },
            { label: <Trans>Home phone</Trans>, value: emHomePhone },
            { label: <Trans>Address</Trans>, value: emAddress },
          ];

          return (
            <Box paddingBottom={24}>
              <ProfileBlock
                title={
                  emergencyContacts.length > 1 ? (
                    <Trans>Emergency contact #{index + 1}</Trans>
                  ) : (
                    <Trans>Emergency contact</Trans>
                  )
                }
                list={list}
              />
            </Box>
          );
        },
      )}
    </React.Fragment>
  );
}

function DocumentsInfoBlock({
  documents,
  contracts,
  educations,
  curriculumVitae,
}) {
  const documentItems = documents.map(
    ({ docType, expDate, files, id, uid }, index) => {
      const title = documentLabelsMap[docType] || '';
      const docList = [
        { label: <Trans>Expire date</Trans>, value: midFormat(expDate) },
      ];

      if (index < 2) {
        docList.push({
          label: <Trans>Number</Trans>,
          value: guidRegex().test(uid) ? null : uid,
        });
      }

      return (
        <DocumentCard key={id} title={title} list={docList} files={files} />
      );
    },
  );

  const contractItems = contracts.map(
    ({ docType, expDate, files, id }, index) => {
      const list = [
        { label: <Trans>Type</Trans>, value: documentLabelsMap[docType] },
        { label: <Trans>Expire date</Trans>, value: midFormat(expDate) },
      ];

      return (
        <DocumentCard
          key={id}
          title={<Trans>Signed contract {index + 1}</Trans>}
          list={list}
          files={files}
        />
      );
    },
  );

  const educationItems = educations.map(
    ({ id, institution, degree, files }, index) => {
      const list = [
        { label: <Trans>College/institution</Trans>, value: institution },
        { label: <Trans>Degree</Trans>, value: documentLabelsMap[degree] },
      ];

      return (
        <DocumentCard
          key={id}
          title={<Trans>Education #{index + 1}</Trans>}
          list={list}
          files={files}
        />
      );
    },
  );
  const empty = (
    <EmptyDocument
      key="ed1"
      title={<Trans>Education</Trans>}
      description={<Trans>No educations was added yet</Trans>}
    />
  );

  const grid = [
    ...documentItems,
    ...contractItems,
    ...(educationItems.length > 0 ? educationItems : [empty]),
    curriculumVitae.length > 0 ? (
      <DocumentCard
        key="ed2"
        title={<Trans>Curriculum vitae (CV)</Trans>}
        {...curriculumVitae[0]}
      />
    ) : (
      <EmptyDocument
        key="ed2"
        title={<Trans>Curriculum vitae (CV)</Trans>}
        description={<Trans>No CV was added yet</Trans>}
      />
    ),
  ];

  return <Masonry gap={24}>{grid}</Masonry>;
}

function AllowancesInfoBlock({ properties }) {
  if (properties.length === 0) {
    return (
      <EmptyDocument
        title={<Trans>Allowances</Trans>}
        description={<Trans>No allowances was added yet</Trans>}
      />
    );
  }

  return (
    <Masonry breakpointCols={{ default: 2, 960: 1 }} gap={24}>
      {properties.map(
        ({ assignDate, files, id, note, returnDate, stateProperty }) => {
          const itemName =
            stateProperty && stateProperty.mark
              ? `${stateProperty.mark} ${stateProperty.model}`
              : '-';

          const itemSerialNumber =
            stateProperty && stateProperty.serialNumber
              ? stateProperty.serialNumber
              : '-';

          const list = [
            { label: <Trans>Item</Trans>, value: itemName },
            { label: <Trans>Serial #</Trans>, value: itemSerialNumber },
            {
              label: <Trans>Date assigned</Trans>,
              value: midFormat(assignDate) || <Trans>Not specified</Trans>,
            },
            {
              label: <Trans>Date returned</Trans>,
              value: midFormat(returnDate) || <Trans>Not specified</Trans>,
            },
            { label: <Trans>Note</Trans>, value: note },
          ];

          return (
            <DocumentCard
              key={id}
              list={list}
              title={stateProperty.category.name}
              files={files}
              blockWidth={[1 / 3]}
            />
          );
        },
      )}
    </Masonry>
  );
}

function DocumentCard({ title, list, files = [], blockWidth = [1 / 2] }) {
  const [preview, setPreview] = React.useState(null);

  return (
    <ProfileBlock title={title} list={list} blockWidth={blockWidth}>
      {files.length > 0 && (
        <Flex marginTop={24} flexDirection="column">
          {files.map(({ fileName, file }, idx) => {
            const maybeExt = fileName.match(fileExtensionRegex());
            const ext = maybeExt ? maybeExt[1] : 'default';
            const { icon: Icon } = fileIcon.find(
              icon => icon.ext === ext || icon.ext === 'default',
            );

            return (
              <Flex
                key={fileName}
                justifyContent="space-between"
                marginBottom={files.length - 1 === idx ? 0 : 12}
              >
                <File
                  alignItems="center"
                  onClick={() => setPreview({ fileName, file, fileExt: ext })}
                >
                  <Icon fontSize={24} />
                  <Typography noWrap>&nbsp;{fileName}</Typography>
                </File>
                <Box>
                  <IconButton
                    color="text"
                    size="small"
                    padding={8}
                    onClick={() =>
                      onDownload({ filename: fileName, url: file })
                    }
                  >
                    <Download />
                  </IconButton>
                </Box>
              </Flex>
            );
          })}
        </Flex>
      )}
      {preview && <Previewer {...preview} onClose={() => setPreview(null)} />}
    </ProfileBlock>
  );
}

function EmptyDocument({ title, description }) {
  return (
    <ProfileBlock title={title}>
      <Flex alignItems="center">
        <Image width={122} src={emptyListIllustration} />
        <Typography>{description}</Typography>
      </Flex>
    </ProfileBlock>
  );
}

function LeaveTips() {
  return (
    <Flex justifyContent="space-around">
      <Flex alignItems="center">
        <Circle width={28} bg="primary" mr={8} />
        <Typography>
          <Trans>Days used</Trans>
        </Typography>
      </Flex>
      <Box width="2px" bg="disabled" style={{ height: 28 }} />
      <Flex alignItems="center">
        <Circle width={28} bg="#e6e6e6" mr={8} />
        <Typography>
          <Trans>Days remaining</Trans>
        </Typography>
      </Flex>
    </Flex>
  );
}

function AvailableLeaveType({ type, user }) {
  const openedDialogId = useStore($leaveAdjustDialogId);
  const { amountDaysPerYear, amountUsedDays, leaveBalance, name } = type;
  const isDialogOpened = openedDialogId === type.id;
  return (
    <Box key={name} style={{ textAlign: 'center' }}>
      <RadialBar
        total={leaveBalance || amountDaysPerYear || 0}
        amount={amountUsedDays || 0}
        content={name}
      />
      <AccessControl allowed={['admin']}>
        <Link as="button" onClick={() => openLeaveAdjustDialog(type.id)}>
          <Trans>change</Trans>
        </Link>
        {isDialogOpened && (
          <LeaveAdjustmentForm
            leave={type}
            user={user}
            onDialogClose={closeLeaveAdjustDialog}
          />
        )}
      </AccessControl>
    </Box>
  );
}

const Circle = styled(Box)`
  border-radius: 50%;
  height: ${({ width }) => width}px;
  box-shadow: 0px 0 0 3px inset rgba(0, 0, 0, 0.2);
`;

const File = styled(Flex)`
  overflow: hidden;
  cursor: pointer;
  :hover > p {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

function midFormat(date) {
  return date ? dayjs(new Date(date)).format('ll') : '';
}

function fullFormat(date) {
  return date ? dayjs(new Date(date)).format('lll') : '';
}

function onDownload({ url, filename }) {
  fetcher
    .get(url, { responseType: 'blob' })
    .then(res => saveFile(res.data, filename));
}
