import React from 'react';
import { i18n } from '@lib/i18n';
import { t, Trans } from '@lingui/macro';
import { Button, ConfirmationDialog, Dialog, Typography } from '@oca/ui';
import { Field, Form } from 'react-final-form';
import { TextFieldWrapper } from '@lib/final-form-oca-ui';
import { Box } from 'rebass';
import { useStore } from 'effector-react';
import {
  $leaveAdjustFetching,
  leaveAdjustSubmitClicked,
} from '../model/profile';

// eslint-disable-next-line react/prop-types
export const LeaveAdjustmentForm = ({ leave, user, onDialogClose }) => {
  const submitting = useStore($leaveAdjustFetching);
  const handleSubmitForm = values => {
    leaveAdjustSubmitClicked({
      ...values,
      user: user.id,
      leaveType: leave.id,
    });
  };

  return (
    <Dialog
      onClickOnClose={onDialogClose}
      title={i18n._(t`Adjust leave`)}
      minWidth={400}
      underlayClickExits={false}
    >
      <Form
        onSubmit={handleSubmitForm}
        validate={values => validate(values, leave)}
        render={({ handleSubmit, values, form }) => {
          const newAmount = leave.leaveBalance + Number(values.adjustment);
          const newAmountLabel = !isNaN(newAmount) ? newAmount : 'N/A';
          return (
            <form onSubmit={handleSubmit}>
              <Box mb={4}>
                <Typography variant="h3">{user.fullName}</Typography>
                <Typography variant="h6" color="textSecondary" fontSize={14}>
                  {user.department.name} ({user.jobTitle})
                </Typography>
              </Box>
              <Box mb={3}>
                <Typography variant="h3" gutterBottom>
                  <Trans>Type</Trans>: {leave.name}
                </Typography>
                <Typography>
                  <Trans>Days used: {leave.amountUsedDays}</Trans>
                </Typography>
                <Typography>
                  <Trans>Leave balance (days): {leave.leaveBalance}</Trans>
                </Typography>
              </Box>
              <Box mb={4}>
                <Field
                  name="adjustment"
                  component={TextFieldWrapper}
                  type="number"
                  label={i18n._(t`Adjust value`)}
                  required
                  spaceAfter
                />
                <Field
                  name="reason"
                  component={TextFieldWrapper}
                  label={i18n._(t`Reason`)}
                  placeholder={i18n._(t`Enter reason for adjustment`)}
                  fullWidth
                  spaceAfter
                  required
                />
              </Box>
              <Box mb={3}>
                <Typography variant="h3" gutterBottom>
                  <Trans>Result</Trans>
                </Typography>

                <Typography>
                  <Trans>
                    Leave balance after adjustment (days): {newAmountLabel}
                  </Trans>
                </Typography>
              </Box>
              <ConfirmationDialog
                title={<Trans>Leave adjustment</Trans>}
                description={
                  <Trans>Are you sure you want to change this leave?</Trans>
                }
                confirmText={<Trans>Yes, I am sure</Trans>}
                cancelText={<Trans>Cancel</Trans>}
              >
                {confirm => {
                  return (
                    <Button
                      type="button"
                      variant="primary"
                      onClick={confirm(form.submit)}
                      disabled={submitting.isLoading}
                    >
                      <Trans>Save</Trans>
                    </Button>
                  );
                }}
              </ConfirmationDialog>
            </form>
          );
        }}
      />
    </Dialog>
  );
};

function validate(values, leave) {
  const errors = {};
  if (!values.adjustment) {
    errors.adjustment = i18n._(t`Value is required`);
  }
  /** adjustment will change leaveBalance value
   * we should prevent setting leaveBalance lower than amountUsedDays
   */
  const adjustment = Number(values.adjustment);

  if (values.adjustment && !isNaN(adjustment)) {
    if (adjustment + leave.leaveBalance < leave.amountUsedDays) {
      errors.adjustment = i18n._(
        t`Leave balance can't be lower than used days`,
      );
    }

    if (leave.leaveBalance + adjustment < 0) {
      errors.adjustment = i18n._(t`Leave balance can't be negative`);
    }
  }

  if (!values.reason) {
    errors.reason = i18n._(t`You need to specify the reason`);
  }
  return errors;
}
