import { fetcher } from '@features/common';

const employeeEndpoint = '/api/v1/employees/';
const employeeDocumentsEndpoint = '/api/v1/employee-documents/';
const employeeEducationsEndpoint = '/api/v1/educations/';
const employeeContactsEndpoint = '/api/v1/contacts/';
const employeePropertiesEndpoint = '/api/v1/employee-properties/';

export function fetchEmployeeList() {
  return fetcher.get(employeeEndpoint);
}

export function fetchSingleEmployee({ id }) {
  return fetcher.get(`${employeeEndpoint + id}/`);
}

export async function fetchEmployeeLeaves({ id }) {
  try {
    const [
      { data: list },
      { data: types },
      {
        data: { results: adjustments },
      },
    ] = await Promise.all([
      fetcher.get(`${employeeEndpoint + id}/leaves/`),
      fetcher.get(`${employeeEndpoint + id}/leave_types/`),
      fetcher.get(`${employeeEndpoint + id}/leave_adjustments/?page_size=1000`),
    ]);

    return { list, types, adjustments };
  } catch (error) {
    return Promise.reject(error);
  }
}

export function createEmployee(data) {
  return fetcher.post(employeeEndpoint, data);
}

export function updateEmployee(data) {
  return fetcher.patch(`${employeeEndpoint + data.id}/`, data);
}

export function removeEmployee(id) {
  return fetcher.delete(`${employeeEndpoint + id}/`);
}

export function terminateEmployee(id) {
  return fetcher.delete(`${employeeEndpoint + id}/terminate/`);
}

// Contacts
export function createEmployeeContact(contact) {
  return fetcher.post(employeeContactsEndpoint, contact);
}

export function updateEmployeeContact(contact) {
  return fetcher.put(`${employeeContactsEndpoint + contact.id}/`, contact);
}

// Documents
export function getEmployeeDocuments(id) {
  return fetcher.get(employeeDocumentsEndpoint);
}

export function createEmployeeDocument(document) {
  return fetcher.post(employeeDocumentsEndpoint, document);
}

export function updateEmployeeDocument(document) {
  return fetcher.put(`${employeeDocumentsEndpoint + document.id}/`, document);
}

// Educations
export function createEmployeeEducation(education) {
  return fetcher.post(employeeEducationsEndpoint, education);
}

export function updateEmployeeEducation(education) {
  return fetcher.put(
    `${employeeEducationsEndpoint + education.id}/`,
    education,
  );
}

// Properties
export async function fetchStateProperties() {
  const { data: categories } = await fetcher.get(
    '/api/v1/property-categories/',
  );
  const { data: items } = await fetcher.get('/api/v1/state-properties/');

  return { categories, items };
}
export function createEmployeeProperty(property) {
  return fetcher.post(employeePropertiesEndpoint, property);
}

export function updateEmployeeProperty(property) {
  return fetcher.put(`${employeePropertiesEndpoint + property.id}/`, property);
}

export function changePassword(data) {
  return fetcher.post('/api/v1/accounts/password-change/', data);
}

export function checkUsername(username) {
  return fetcher.get(`/api/v1/accounts/check-username/`, {
    params: { username },
  });
}

export function adjustEmployeeLeave(data) {
  return fetcher.post('/api/v1/leave_adjustments/', data);
}
