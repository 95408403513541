import React from 'react';
import { t } from '@lingui/macro';

import { Documents } from '@oca/icons';
import { i18n } from '@lib/i18n';
import { omit } from '@lib/help-fns';
import { DocumentsRootPage } from './pages/root';
import { SelfFilesPage } from './pages/self-files';
import { FavoritePage } from './pages/favorite';
import { SharedWithMePage } from './pages/shared-with-me';
import { SharedByMePage } from './pages/shared-by-me';
import { CompanyFilesPage } from './pages/company-files';

const subroutes = [
  {
    path: '/documents/my-files',
    component: SelfFilesPage,
    navigation: {
      name: i18n._(t`My Files`),
      shortName: 'MF',
      onlyTopLevelLink: true,
    },
    exact: true,
  },
  {
    path: '/documents/favorite',
    component: FavoritePage,
    navigation: {
      name: i18n._(t`Favorite`),
      shortName: 'F',
      onlyTopLevelLink: true,
    },
    exact: true,
  },
  {
    path: '/documents/shared-with-me',
    navigation: {
      name: i18n._(t`Shared with me`),
      shortName: 'SW',
      onlyTopLevelLink: true,
    },
    component: SharedWithMePage,
    exact: true,
  },
  {
    path: '/documents/shared-by-me',
    navigation: {
      name: i18n._(t`Shared by me`),
      shortName: 'SB',
      onlyTopLevelLink: true,
    },
    component: SharedByMePage,
    exact: true,
  },
  {
    path: '/documents/company-files',
    component: CompanyFilesPage,
    navigation: {
      name: i18n._(t`Company Files`),
      shortName: 'CF',
      onlyTopLevelLink: true,
    },
    exact: true,
  },
];

export const routes = [
  {
    path: '/documents',
    component: DocumentsRootPage,
    navigation: { name: i18n._(t`Documents`), icon: <Documents /> },
    routes: subroutes.reduce(reduceSubroutes, []),
  },
];

/**
 * Duplicate every route to match nested path with `:id`
 */
function reduceSubroutes(acc, item) {
  return acc.concat([
    item,
    {
      ...omit(['navigation'], item),
      parentPath: item.path,
      path: `${item.path}/:id`,
      name: item.navigation.name,
      exact: false,
    },
  ]);
}
