import {
  pick,
  getFullName,
  diffArrays,
  mapObject,
  filterObject,
  isEmpty,
  omit,
} from '@lib/help-fns';
import { diff } from 'deep-object-diff';
import { DOCUMENTS, EMPLOYEE_LIST_ENTITIES } from '../../model/static';

/**
 * @typedef {import('../../model/profile').Employee} Employee
 */

/**
 * Returns employee's basic information difference
 * @param {Employee} prevValues
 * @param {Employee} values
 * @returns {Object}
 */
export function getEmployeeBasicDiff(prevValues, values) {
  const prevBasic = omit(EMPLOYEE_LIST_ENTITIES, prevValues);
  const nextBasic = omit(EMPLOYEE_LIST_ENTITIES, values);

  return diff(prevBasic, nextBasic);
}

/**
 * Returns difference between employee entity(contacts, documents, educations, properties) values
 * @param {Employee} prevValues
 * @param {Employee} values
 * @returns {{ added: Object, deleted: Object, updated: Object }}
 */
export function getEmployeeEntitiesDiff(prevValues, values) {
  const prevEntities = normalizeEntities(prevValues, EMPLOYEE_LIST_ENTITIES);
  const nextEntities = normalizeEntities(values, EMPLOYEE_LIST_ENTITIES);

  const added = diffArrayEntities(
    nextEntities,
    prevEntities,
    item => item.id === undefined,
  );
  const deleted = diffArrayEntities(prevEntities, nextEntities, (item, next) =>
    next.every(i => i.id !== item.id),
  );
  const updated = diffArrayEntities(
    nextEntities,
    prevEntities,
    (item, next) => {
      const match = next.find(({ id }) => id === item.id);
      if (match) {
        return !isEmpty(diff(match, item));
      }
      return false;
    },
  );

  return { added, deleted, updated };
}

export function mapDataId({ data }) {
  return data ? data.id : '';
}

export function mapEntity(item) {
  return {
    ...item,
    files: item.files.map(file => ({ ...file, deleted: false })),
  };
}

export function normalizeEntities(values, entityKeys) {
  const {
    documents = [],
    contracts = [],
    curriculumVitae = [],
    ...entities
  } = pick(entityKeys, values);
  return {
    ...entities,
    documents: [...documents, ...contracts, ...curriculumVitae],
  };
}

/**
 *
 * @param {Object<string, Array>} left
 * @param {Object<string, Array>} right
 * @param {Function} comparator Calls with current iterating item and right array
 */
export function diffArrayEntities(
  left,
  right,
  comparator = (item, rightArray) => !rightArray.includes(item),
) {
  const diffed = mapObject(
    (arr, key) => diffArrays(arr, right[key], comparator),
    left,
  );

  return filterObject(val => !isEmpty(val), diffed);
}

/**
 *
 * @param {Employee} employee
 */
export function transformEmployee(employee) {
  const entities = EMPLOYEE_LIST_ENTITIES.reduce(
    (acc, entity) => ({
      ...acc,
      [entity]: employee[entity] || [],
    }),
    {},
  );

  const rawDocuments = entities.documents;
  const contractTypes = [DOCUMENTS.CONTRACT, DOCUMENTS.NDA];

  const contracts = rawDocuments.filter(item =>
    contractTypes.includes(item.docType),
  );
  const documents = rawDocuments.filter(
    item =>
      !contractTypes.includes(item.docType) && item.docType !== DOCUMENTS.CV,
  );
  const curriculumVitae = rawDocuments.filter(
    item => item.docType === DOCUMENTS.CV,
  );

  return {
    ...employee,
    curriculumVitae,
    fullName: getFullName(employee),
    documents: documents.map(mapEntity),
    contracts: contracts.map(mapEntity),
    educations: entities.educations.map(mapEntity),
    properties: entities.properties.map(mapEntity),
    contacts: entities.contacts,
  };
}

export function getEntityFiles(entity, predicate) {
  return entity && entity.files
    ? entity.files.reduce(
        (acc, file) => (predicate(file) ? acc.concat([file]) : acc),
        [],
      )
    : null;
}
