import { createDomain, createEffect, createEvent, sample } from 'effector';
import dayjs from 'dayjs';
import { locales, i18n, isRTL } from '@lib/i18n';
import { loadFromStorage, saveToStorage } from '@lib/effector-utils';

export const localeDomain = createDomain('i18n');

loadFromStorage(localeDomain, window.localStorage);
saveToStorage(localeDomain, window.localStorage);

export const setLocale = createEvent();
export const $locale = localeDomain.store(locales.en.code, { name: 'locale' });
const applyLocaleFx = createEffect({
  name: 'apply locale',
  handler: () => {
    window.location.reload();
  },
});

$locale.on(setLocale, (_, locale) => locale);

$locale.watch(newLocale => {
  i18n.activate(newLocale);
  dayjs.locale(newLocale);
  // Set html attributes on next tick
  setTimeout(() => {
    const dir = isRTL() ? 'rtl' : 'ltr';

    document.documentElement.setAttribute('dir', dir);
    document.documentElement.setAttribute('lang', newLocale);
  });
});

sample({
  source: setLocale,
  target: applyLocaleFx,
});
