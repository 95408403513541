import Axios from 'axios';
import { guard, merge } from 'effector';
import { not } from '@lib/help-fns';
import { createFormData } from '@lib/create-form-data';
import { notify } from '@lib/notifier';
import { i18n } from '@lib/i18n';
import { t } from '@lingui/macro';
import { $uploadProgress, $uploadFile } from './file-browser.stores';
import {
  setUploadProgress,
  uploadFileRequest,
  startFileUploading,
  cancelFileUploading,
} from './file-browser.events';
import { uploadFile, uploadFileToDirectory } from '../api';
import { FileBrowserGate } from './file-browser.view';

let cancelFn;

$uploadFile
  .on(startFileUploading, (_, { file }) => file)
  .reset(FileBrowserGate.close, cancelFileUploading, uploadFileRequest.done);

$uploadProgress
  .on(setUploadProgress, (_, value) => value)
  .reset(uploadFileRequest.done);

guard({
  source: startFileUploading.map(mapUploading),
  filter: uploadFileRequest.pending.map(not),
  target: uploadFileRequest,
});

merge([cancelFileUploading, FileBrowserGate.close]).watch(() => {
  if (cancelFn) {
    cancelFn('Cancelled by user');
  }
});

uploadFileRequest.doneData.watch(({ data }) => {
  notify.success(i18n._(t`File ${data.name} is uploaded`));
});

uploadFileRequest.fail.watch(({ error }) => {
  const msg = error.message;
  notify.error(i18n._(t`Could not upload file. ${msg}`));
});

uploadFileRequest.use(data => {
  const source = Axios.CancelToken.source();
  cancelFn = source.cancel;
  const hasDirectory = data.get('directory') !== '';
  const config = {
    cancelToken: source.token,
    onUploadProgress: event =>
      setUploadProgress(Math.round((event.loaded * 100) / event.total)),
  };
  if (hasDirectory) {
    return uploadFileToDirectory({
      data,
      config,
    });
  }

  return uploadFile({
    data,
    config,
  });
});

function mapUploading({ file, folder }) {
  return createFormData({ file, directory: folder }, true);
}
