import React from 'react';
import { t } from '@lingui/macro';
import { i18n } from '@lib/i18n';

const AssetsList = React.lazy(() =>
  import('./pages/assets-list').then(module => ({
    default: module.AssetsList,
  })),
);

export const routes = [
  {
    access: ['admin', 'director'],
    path: '/hr/assets',
    component: AssetsList,
    exact: true,
    navigation: {
      name: i18n._(t`Allowances`),
      shortName: 'AL',
    },
  },
];
